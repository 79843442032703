import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/ui-elements/ui-elements/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { View } from 'react-native';
import { PostComment } from '@components/PostComment';
import { comment1, comment2, comment3 } from './data/postComments';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "postcomment"
    }}>{`PostComment`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={PostComment} mdxType="Props" />
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={1} __code={'<PostComment comment={comment1} authUser={{ id: \'id1\', isAdmin: false }} />\n<PostComment comment={comment2} authUser={{ id: \'id2\', isAdmin: false }} />\n<PostComment comment={comment2} authUser={{ id: \'id3\', isAdmin: true }} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      PostComment,
      comment1,
      comment2,
      comment3,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <PostComment comment={comment1} authUser={{
        id: 'id1',
        isAdmin: false
      }} mdxType="PostComment" />
  <PostComment comment={comment2} authUser={{
        id: 'id2',
        isAdmin: false
      }} mdxType="PostComment" />
  <PostComment comment={comment2} authUser={{
        id: 'id3',
        isAdmin: true
      }} mdxType="PostComment" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      